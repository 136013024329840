import { Box, Button, Container, Typography } from '@mui/joy'
import Header from '../components/shared/header'
import Image from 'next/image'
import AudreyHack from '../components/shared/audrey-hack'
import Link from 'next/link'

export default function Custom404() {
  return (
    <>
      <Header />

      <Box height={80} />

      <Container
        maxWidth="md"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <Image src={'/assets/logo.png'} alt="logo" width={56} height={56} />
        </div>

        <Box height={148} />

        <Typography level="h1" textAlign="center" fontSize={64}>
          404
        </Typography>

        <Box height={24} />

        <Typography level="h2" textAlign={'center'}>
          This page could not be found.
        </Typography>

        <Box height={40} />

        <Button
          variant="outlined"
          component={Link}
          sx={{
            width: 352,
          }}
          href="/"
        >
          <AudreyHack>Go back</AudreyHack>
        </Button>
      </Container>
    </>
  )
}
